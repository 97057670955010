import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SecurityService } from 'src/app/main/security/security.service';
import {SearchCountryField, CountryISO, PhoneNumberFormat, NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import { DialogPhoneValidationCodeComponent } from './dialog-phone-validation-code/dialog-phone-validation-code.component';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {environment} from "../../../../environments/environment";
import {STATUS_ERROR, STATUS_OK} from "../../../app.constants";
import {SharedModule} from "../../../shared/shared.module";
import {MatRippleModule} from "@angular/material/core";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialAuthServiceConfig, SocialUser
} from "@abacritt/angularx-social-login";
import {SignInComponent} from "../../security/sign-in/google/sign-in.component";
import {alphabeticValidator, inputValidator} from "../../../helpers/input-validator";
import {RecaptchaComponent} from "../../../pages/recaptcha/recaptcha.component";

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SharedModule,
    NgxIntlTelInputModule,
    MatDialogModule,
    MatRippleModule,
    SignInComponent,
    RecaptchaComponent,
  ],
  providers : [
    SocialAuthService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider
            ('239415642847-ui5ojj2mqe1s4n310vvgompi3v1sv73l.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('426812791556548')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ]
})
export class InscriptionComponent implements OnInit {
  @Input() typeAuth = '';
  public validCode: boolean = false;
  public signUpForm: FormGroup;
  public hide = true;
  errorMessage: string = "";
  show = false;
  genre = "Mme";
  phone: any;
  gsm: any;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  selectedCountryISO = CountryISO.Morocco;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Morocco, CountryISO.France];
  isAcceptedTerms: boolean = false;
  successMessage: string = "";
  socialUser : SocialUser;
  isLoggedin : boolean = undefined;
  user:any;
  public notRobotValidation: boolean = false;

  constructor(
      public _formbuilder: FormBuilder,
      public router: Router,
      public snackBar: MatSnackBar,
      public _securityService: SecurityService,
      public _matdialog: MatDialog,
      private _socialAuthService: SocialAuthService,
  ) { }

  ngOnInit() {
    //Init Sign up Form
    this.initFormSignUp();
    //Init Sign up Social media
    this.initSignUpSocialMedia();
    //Init auto selected country
    this.initAutoSelectCountry();
  }

  createFakeGoogleWrapper = () => {
    const googleLoginWrapper = document.createElement('div');
    googleLoginWrapper.style.display = 'none';
    googleLoginWrapper.classList.add('custom-google-button');
    document.body.appendChild(googleLoginWrapper);
    window.google.accounts.id.renderButton(googleLoginWrapper, {
      type: 'icon',
      width: '200',
    });
    const googleLoginWrapperButton = googleLoginWrapper.querySelector(
        'div[role=button]'
    ) as HTMLElement;

    return {
      click: () => {
        googleLoginWrapperButton?.click();
      },
    };
  };

  private initFormSignUp() {
    this.signUpForm = this._formbuilder.group({
      genre: this.genre,
      lastname: this._formbuilder.control('', [Validators.required, Validators.maxLength(30), alphabeticValidator]),
      firstname: this._formbuilder.control('', [Validators.required, Validators.maxLength(30), alphabeticValidator]),
      email : new FormControl(
          '',
          [
            Validators.required,
            Validators.email,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
          ]
      ),
      mobile: this._formbuilder.control(null, [Validators.required]),
      password: this._formbuilder.control('', [Validators.required, inputValidator]),
      cgu: this._formbuilder.control('', [Validators.required])
    });
  }

  private initAutoSelectCountry(){
    let chosenLocationStr = localStorage.getItem("chosenLocation");
    if(chosenLocationStr) {
      const chosenLocation = JSON.parse(chosenLocationStr);
      if(chosenLocation?.country === CountryISO.Morocco){
        this.selectedCountryISO = CountryISO.Morocco;
      }else if(chosenLocation?.country === CountryISO.France){
        this.selectedCountryISO = CountryISO.France;
      }
    }
  }

  private initSignUpSocialMedia(){
    this._socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      this.isLoggedin = (user != null && user != undefined);
      this._securityService.socialAuthentication(this.socialUser).subscribe((res) =>{
        if(res?.status == STATUS_OK) {
          //Init inscription after signed up
          this._securityService.initConnection().subscribe();
          if(this.typeAuth === 'booking') {
            this._securityService.nextStepSubject.next(true);
          }else {
            setTimeout(()=>{
              this.router.navigate(['/']);
            }, 1000)
          }
        } else {
          this.handleErrorSignUp(res);
        }
      });
    });
  }

  validateInscriptionBooking() {
    if (this.signUpForm.valid) {
      const data = {
        "genre": this.signUpForm.value.genre,
        "lastname": this.signUpForm.value.lastname,
        "firstname": this.signUpForm.value.firstname,
        "email": this.signUpForm.value.email,
        "mobile": this.signUpForm.value.mobile.number,
        "mobile_phone": this.signUpForm.value.mobile.e164Number,
        "password": this.signUpForm.value.password,
        "prefix": this.signUpForm.value.mobile.dialCode,
      };
      this._securityService.initInscription(data).subscribe((res) => {
        if(res.status === STATUS_OK){
          const dialogRef = this._matdialog.open(DialogPhoneValidationCodeComponent, {
            disableClose: false,
            data: {
              title: 'Validation',
            },
            panelClass:"bg-white"
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
              if(result.validCode){
                this.successMessage = "Votre compte est créé avec succès";
                this.snackBar.open(this.successMessage, '×', {
                  verticalPosition: 'top',
                  duration: 5000,
                  panelClass: ['green-snackbar'],
                });
                //Init inscription after signed up
                this._securityService.initConnection().subscribe();
                if(this.typeAuth === 'booking') {
                  // this._bookingComponent.nextStep();
                  this._securityService.nextStepSubject.next(true);
                }else {
                  setTimeout(()=>{
                    this.router.navigate(['/']);
                  }, 1000)
                }
              }
            }
          });
        }else {
          this.handleErrorSignUp(res)
        }
      });
    }
  }

  googleSignin() {
    this.createFakeGoogleWrapper().click();
  }

  signInWithFB(): any {
    this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then();
  }

  private handleErrorSignUp(res: any) {
    if(res.status === STATUS_ERROR) {
      if(res?.message?.email_error === true){
        this.errorMessage = "L'email existe déjà";
      }else if (res?.message?.username_error === true){
        this.errorMessage = "Nom d'utilisateur existe déjà";
      }else if (res?.message?.mobile_phone_error === true){
        this.errorMessage = "Mobile existe déjà";
      }else{
        this.errorMessage = "L'email ou le mobile existe déjà";
      }
      this.snackBar.open(this.errorMessage, '×', {
        verticalPosition: 'top',
        duration: 5000,
        panelClass: ['red-snackbar'],
      });
    }else{
      this.errorMessage = "Un erreur est survenue!";
      this.snackBar.open(this.errorMessage, '×', {
        verticalPosition: 'top',
        duration: 5000,
        panelClass: ['red-snackbar'],
      });
    }
  }

  validateRecaptcha($event: boolean) {
    if($event){
      this.notRobotValidation = true;
    }
  }
  protected readonly environment = environment;
}

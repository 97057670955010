import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { BookingComponent } from "../booking.component";
import { MatStepper } from "@angular/material/stepper";
import { BookingService } from "../booking.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { STATUS_OK } from "../../../app.constants";
import {ProchesService} from "../../patient-area/mes-proches/proches.service";
import {SharedModule} from "../../../shared/shared.module";
import {SecurityService} from "../../security/security.service";
import {getAvatar} from "../../../../@ea/helpers/image-utils";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
@Component({
  selector: "app-choice",
  templateUrl: "./choice.component.html",
  styleUrls: ["./choice.component.scss"],
  standalone:true,
    imports: [SharedModule, TooltipModule]
})
export class ChoiceComponent implements OnInit, OnDestroy {

  @ViewChild('horizontalStepper') private myStepper: MatStepper;
  selected=-1;
  user: any;
  dataSource : any[] = [];
  errorMessage: string = "";
  dialogRef: any;
  private _unsubscribeAll: Subject<any>;
  userData: any;
  commentControl: FormControl = new FormControl('', []);

  /**
   * Constructor
   *
   * @param _procheService
   * @param {MatDialog} _matDialog
   * @param bookingComponent
   * @param _bookingService
   * @param _securityService
   * @param router
   * @param snackBar
   */
    constructor(private _procheService: ProchesService,
                public _matDialog: MatDialog,
                private bookingComponent : BookingComponent,
                private _bookingService : BookingService,
                private _securityService : SecurityService,
                private router: Router,
                private snackBar: MatSnackBar) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

  /**
   * On init
   */
  ngOnInit(): void {
      this._securityService.userDataSubject.subscribe(value => {
          if (value){
              this.userData = value;
          }
      })
      this.userData = JSON.parse(localStorage.getItem("userdata"));
      this._procheService.getMesProches("").subscribe()
      this._procheService.mesProchesSubject.subscribe(
          (value : any[]) => {
              if(value) {
                  this.dataSource = value.sort((a,b)=>(b.is_principal === true) ? 1 : -1);
                  this.dataSource = this.dataSource.map((e)=>{
                      if(e.is_principal === true){
                          return {...e, category: "Vous", avatar: this.userData?.avatar}
                      }else return e;
                  });
              }
          }
      )
      this._procheService.prochePrincipalSubject.subscribe(
          (value) => {
              if(value){
                  this.selected = value.reference;
              }
          }
      )
  }

  confirmerRdv() {
    if (this.selected >-1 ) {
        const idProche = this.selected;
        const proche = this.dataSource.find( proche => proche.reference == this.selected);
        const data = {
            idProche : idProche,
            comment : this.commentControl.value
        };
        const data_ = {
            proche : proche,
            comment : this.commentControl.value
        }
        this._bookingService.confirmerRdv(data)
        .subscribe((value)=>{
            localStorage.removeItem('appointement');
            if (value.status === STATUS_OK) {
                this._bookingService.selectProche(data_);
                this.bookingComponent.myStepper.next();
            }else {
                this.errorMessage = "Erreur de confirmation du rendez-vous, veuillez réessayer plus tard";
                this.snackBar.open(this.errorMessage, '×', {
                    verticalPosition: 'top',
                    duration: 5000,
                    panelClass: ['red-snackbar'],
                });
                setTimeout(()=>{
                    this.router.navigate(["/"])
                }, 6000)
            }
        })
    }else{
      this.errorMessage = "Aucun patient sélectionné.";
      this.snackBar.open(this.errorMessage, '×', {
        verticalPosition: 'top',
        duration: 5000,
        panelClass: ['red-snackbar'],
      });
    }
  }

  previous(){
    this.bookingComponent.oneStepBack();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
     // this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }
    onChange(id) {
      if(this.selected == id)
          this.selected=-1;
      else
          this.selected = id;
    }

    protected readonly getAvatar = getAvatar;
}
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import {EaHttpAbstract} from "../../../../@ea/abstract/http.abstract";
import {map} from "rxjs/operators";
import {UtilsService} from "../../../helpers/utils.service";
import {STATUS_OK} from "../../../app.constants";

@Injectable({
    providedIn: 'root'
})

export class  ProfileService
{
    _url_java: any;
    private accountInfo = new BehaviorSubject<any>(null);
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param _eaHttpAbstract
     * @param _utilsService
     */
    constructor(
        private _httpClient: HttpClient,
        private _eaHttpAbstract: EaHttpAbstract,
        private _utilsService: UtilsService,
    ) {
        // Set the defaults
        this._url_java = environment.url_java;
        // this.accountInfo.next(this._utilsService.getSession('userdata'));
    }

    get accountInfo$(): Observable<any> {
        return this.accountInfo.asObservable();
    }

    set setAccountInfo(value) {
        this.accountInfo.next(value);
    }

    uploadProfileData(formData: FormData) {
        return this._httpClient
            .post(`${this._url_java}/patient_area/update_profile`, formData, {
                withCredentials: true,
                responseType: "json",
                observe: "response",
            })
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }

    removePhotoProfile() {
        return this._httpClient
            .delete(`${this._url_java}/patient_area/profile_photo`, {
                withCredentials: true,
                responseType: "json",
                observe: "response",
            })
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }

    getInfosAccount(): Observable<any>
    {
        return this._eaHttpAbstract.post("/patient_area/get_details", {})
            .pipe(
                map((res: any)=>{
                    if(res.status === STATUS_OK){
                        this.accountInfo.next(res.data);
                    }
                    return res;
                })
            );
    }
}

import {Input, Component, OnInit, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { PlanningService } from 'src/app/apps/planning/planning.service';
import { TimesSlot } from 'src/app/apps/planning/planning.model';
import { SharedModule } from 'src/app/shared/shared.module';
import {STATUS_OK} from "../../../../../app.constants";
import * as moment from "moment/moment";
import {DateAdapter} from "@angular/material/core";
import {BookingInProgressService} from "../../../../../shared/booking-in-progress/booking-in-progress.service";
import {emptyPlanningData} from "../../../../../apps/planning/empty-planning-data";

@Component({
  selector: 'app-planning-list',
  standalone: true,
  imports: [ SharedModule ],
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss'],
})
export class PlanningComponent implements OnInit {
  @Input() practice: any;
  @Input() type: string;
  @Input() init_times_slots: any;
  @Input() reasons: any;
  @Output() emitSelectSlot = new EventEmitter<any>();

  date_init: Date = new Date();
  display_days: number = 4;
  isToday: boolean = true;
  spin: boolean = false;
  rendezVous: any;
  mobwidth: any;
  public timesSlot: TimesSlot[] = [];
  nextDateApp: Date = null;
  public errorPractice       : boolean = false;
  public errorConfig         : boolean = false;
  public errorScheduler      : boolean = false;
  public errorOccurred       : boolean = false;
  public errorNewPatient     : boolean = false;
  public emptyPlanning       : boolean = false;
  public emptyPlanningTimes  : boolean = false;

  constructor(
    private _planningService: PlanningService,
    private router : Router,
    private dateAdapter: DateAdapter<Date>,
    private _bookingInProgressService: BookingInProgressService
  ) {
    this.mobwidth = window.screen.width;
    this.dateAdapter.setLocale('fr');
  }
  idMotifs: number = -1;
  ngOnInit(): void {
    if (this.mobwidth <= 450) {
      this.display_days = 3;
    }
    this.idMotifs = this.reasons?.reference;
    if(!this.practice?.featured){
      this.init_times_slots = emptyPlanningData(this.display_days);

    }else if (!this.init_times_slots){
      this.load_TimesSlot();
    }
    this.patchValues();
  }
  load_TimesSlot(): void {
    let params :any = {};
    if(this.nextDateApp){
      params.date_init = (moment(this.nextDateApp)).format('yyyy-MM-DD');
      this.date_init = moment(this.nextDateApp).toDate();
    }else {
      params.date_init = (moment(this.date_init)).format('yyyy-MM-DD');
    }
    const params_timesSlote = {
      date_init: params.date_init,
      display_days: this.display_days,
      reference: this.getPracticeRef(),
      refType: this.idMotifs
    };
    this.spin = true;
    this._planningService.getTimesSlot(params_timesSlote).subscribe((res) => {
      this.spin = false;
      if (res.status === STATUS_OK) {
        this.init_times_slots = res?.data;
        this.patchValues();
      }
    });
  }
  private getPracticeRef(){
    return this.practice.reference_practice?
        this.practice.reference_practice:
        this.practice.reference;
  }
  startProcessRdv(time: any, timeSlot: any): void {
    const dataParams = {
      idPraticien: this.getPracticeRef(),
      reference: this.getPracticeRef(),
      hour: time.full_hour,
      is_default: '',
      type_ref: this.idMotifs,
      date_init: timeSlot.date,
    };
    this._planningService.startProcessRdv(dataParams).subscribe((res) => {
      this.rendezVous = {
        praticien: dataParams.idPraticien,
        Hour: dataParams.hour,
        isconnect: false,//todo always false
        date: dataParams.date_init,
        motif: dataParams.type_ref,
        place: this.practice.address,
      };
      this._planningService.selectedTime({
        rendez_vous: this.rendezVous,
      });
      const appointement = {
        practice: this.practice?.infos?this.practice?.infos:this.practice,
        time: time,
        timeSlot: timeSlot,
        motif: this.reasons?this.reasons:this.idMotifs,
        created_at: new Date().toISOString(),
      };
      localStorage.setItem('appointement', JSON.stringify(appointement));
      this._bookingInProgressService.isAlreadyClosedBookingInProgress.next(false);
      this.emitSelectSlot.emit(appointement);
      this.router.navigate(['/booking']);
    });
  }
  resetVarErrors(){
    this.nextDateApp = null;
    this.emptyPlanning = false;
    this.errorPractice = false;
    this.errorScheduler = false;
    this.errorConfig = false;
    this.errorOccurred = false;
    this.emptyPlanningTimes = false;
  }
  patchValues(){
    this.resetVarErrors();
    if(this.init_times_slots?.planning){
      this.timesSlot = this.init_times_slots?.planning;

    }
    if(this.init_times_slots?.allows_new_patients === true){
      this.errorNewPatient = true;
      this.timesSlot = emptyPlanningData(this.display_days)?.planning;

    }else if(this.init_times_slots?.error_practice ||
        this.init_times_slots?.error_config ||
        this.init_times_slots?.error_scheduler){
      this.errorPractice = true;
      this.errorScheduler = true;
      this.errorConfig = true;
      this.errorOccurred = true;
      this.timesSlot = emptyPlanningData(this.display_days)?.planning;

    }else if(this.init_times_slots?.empty_planning){
      this.emptyPlanning = true;

    }else if(this.init_times_slots?.empty_planning_times){
      this.emptyPlanningTimes = true;

      if(this.init_times_slots?.next_available_date){
        this.nextDateApp = this.init_times_slots?.next_available_date;

      }else{
        //todo: what to do in this case
        this.errorOccurred = true;

      }
    }else if(!this.practice?.featured){
      this.errorOccurred = true;
    }
  }

  nextDays(): void {
    this.nextDateApp = null;
    this.date_init = new Date(
      this.date_init.setDate(this.date_init.getDate() + this.display_days)
    );
    this.spin = false
    this.isToday = false;
    this.load_TimesSlot();
    this.patchValues();
  }

  previousDays(): void {
    this.nextDateApp = null;
    this.ischosenDateToday();
    const newDate = moment(this.date_init).subtract(this.display_days, 'days');
    this.date_init = newDate.toDate();
    this.load_TimesSlot();
    this.ischosenDateToday();
  }
  ischosenDateToday(): any {
    if(moment(this.date_init).isBefore(moment(), 'day')){
      this.date_init = new Date();
    }else{
      this.isToday = moment(this.date_init).isSame(moment(), 'day');
    }
  }
  goToNextDateApp() {
    this.load_TimesSlot();
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MoteurRechercheService {
    paramsSubject : Subject<any> = new Subject<any>();
    dataSubject : Subject<any> = new Subject<any>();
    citySubject : Subject<any> = new Subject<any>();
    specialitySubject : Subject<any> = new Subject<any>();
    /**
     * constructor
     *
     */
    constructor() {}

    /*
    * Getter & setter
    * */
    paramObservable(): Observable<any> {
        return this.paramsSubject.asObservable();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    emitParams(params){
        this.paramsSubject.next(params);
    }
    emitData(data){
      this.dataSubject.next(data);
    }
    searchCity(paramCity){
       this.citySubject.next(paramCity);
    }
    searchSpeciality(paramSpeciality){
      this.specialitySubject.next(paramSpeciality);
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {UserAuthenticated} from "../../helpers/guards/user-authenticated";
import {SecurityService} from "../../main/security/security.service";
import {STATUS_OK} from "../../app.constants";

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
  standalone: true,
  imports:[
      MatCardModule,
      MatIconModule,
  ]
})
export class MaintenancePageComponent implements OnInit {

  constructor(public router:Router, public _securityService: SecurityService) { }

  ngOnInit() {
    this._securityService.authenticated()
        .subscribe((res: any) => {
          if (res.connected) {
            this.router.navigate(['/']);
          }
        })
  }

  public goHome(): void {
    this.router.navigate(['/']);
  }

  ngAfterViewInit(){
    document.getElementById('preloader').classList.add('hide');    
  }

}

import { Component } from '@angular/core';
import {InscriptionComponent} from "../../booking/inscription/inscription.component";
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  standalone: true,
  imports: [
    InscriptionComponent
  ]
})
export class SignUpComponent {

}
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';

import { LocationService } from './helpers/location.service';
import { SeoService } from './helpers/seo.service';
import { IndexService } from './main/index/index.service';
import { ResultHeroMapService } from 'src/app/apps/result-hero-map/result-hero-map.service';
import { ListService } from 'src/app/main/search-area/result/list/list.service';
import { PlanningService } from 'src/app/apps/planning/planning.service';
import { MoteurRechercheService } from './extra/moteur-recherche/moteur-recherche.service';
import { DetailPracticeService } from './main/search-area/result/detail-practice/detail-practice.service';
import { DatePipe } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileService } from './main/patient-area/profile/profile.service';
import { ProchesService } from './main/patient-area/mes-proches/proches.service';
import { AppointmentsService } from './main/patient-area/appointments/appointments.service';
import { AccountService } from './main/patient-area/account/account.service';
import {MaintenancePageComponent} from "./pages/maintenance-page/maintenance-page.component";
import {BookingComponent} from "./main/booking/booking.component";
import {AuthGuard} from "./helpers/guards/auth.guard";
import {NoAuthGuard} from "./helpers/guards/noAuth.guard";


export const routes: Routes = [
    { 
        path: '', 
        component: PagesComponent, children: [
            { 
                path: '',
                loadComponent: () => import('./main/index/index.component').then(c => c.IndexComponent),
                providers: [
                    IndexService,
                    PlanningService
                ]
                
            },
            {
                path: '',
                loadChildren: () => import('./main/security/security.routing'),
                canActivate: [NoAuthGuard],
            },
            { 
                path: ':country/:city/:speciality',
                loadComponent: () => import('./main/search-area/result/result.component').then(c => c.ResultComponent),
                providers: [ 
                    ListService,
                    PlanningService,
                    ResultHeroMapService,
                    MoteurRechercheService,
                    DetailPracticeService
                ], resolve: [
                    // ListResolver
                ]
            },
            { path: 'booking', component: BookingComponent },
            {
                path: 'patient-area',
                loadChildren: () => import('./main/patient-area/patient-area.module').then(m => m.PatientAreaModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            /*{
                path: 'about', loadComponent: () => import('./theme/components/about/about.component').then(c => c.AboutComponent)
            },
            {
                path: 'jobs', loadComponent: () => import('./theme/components/jobs/jobs.component').then(c => c.JobsComponent)
            },
            {
                path: 'privacy-security', loadComponent: () => import('./theme/components/privacy-security/privacy-security.component').then(c => c.PrivacySecurityComponent)
            },
            {
                path: 'help-center', loadComponent: () => import('./theme/components/help-center/help-center.component').then(c => c.HelpCenterComponent)
            },
            {
                path: 'partners-benefits', loadComponent: () => import('./theme/components/partners-benefits/partners-benefits.component').then(c => c.PartnersBenefitsComponent)
            },
            {
                path: 'contact', loadComponent: () => import('./theme/components/contacts/contacts.component').then(c => c.ContactsComponent)
            },*/
            // { 
            //     path: '',
            //     loadComponent: () => import('./main/search-area/result/detail-practice/detail-practice.component').then(c => c.DetailPracticeComponent),
            //     providers : [
            //         DetailPracticeService
            //     ],
            // },
            {
                path: ':country/:city/:category/:speciality/:url',
                loadComponent: () => import('./main/search-area/result/detail-practice/practice/practice.component').then(c => c.PracticeComponent),
                providers: [
                    PlanningService,
                    DetailPracticeService,
                    DatePipe,
                    {provide:MatDialogRef , useValue:{} },
                ]
            }
            // { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
            // { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
            // { path: 'properties', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule) },
            // { path: 'agents', loadChildren: () => import('./pages/agents/agents.module').then(m => m.AgentsModule) },
            // { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule) },
            // { path: 'pricing', loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule) },
            // { path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule) },
            // { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
            // { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
            // { path: 'terms-conditions', loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
            // { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) }, 
            // { path: 'submit-property', loadChildren: () => import('./pages/submit-property/submit-property.module').then(m => m.SubmitPropertyModule) }   
        ],
        resolve : {
            network : LocationService,
           // data: ListResolver,
           //  security : SecurityService
        }
    },
    // { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
    { path: 'lock-screen', component: LockScreenComponent },
    { path: 'maintenance', component: MaintenancePageComponent },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            // relativeLinkResolution: 'legacy',
            initialNavigation: 'enabledBlocking', // for one load page, without reload
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ],
    providers : [
      LocationService,
      SeoService
    ]
})
export class AppRoutingModule { }
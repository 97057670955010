import { Component, OnInit } from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import { AppService } from 'src/app/app.service';
import { SecurityService } from 'src/app/main/security/security.service';
import { Subject } from "rxjs";
import {STATUS_OK} from "../../../app.constants";
import {DEFAULT_PHOTO, PATH_AVATAR} from "../../../constants/constants";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {NgIf} from "@angular/common";
import {getAvatar, handleImageError} from "../../../../@ea/helpers/image-utils";

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        NgIf,
        RouterLink
    ]
})
export class UserMenuComponent implements OnInit {

  isConnected : boolean = false;
  user :any;
  destroy = new Subject();
  constructor(public appService:AppService,
    private _securityService: SecurityService,
    private router: Router) {}

  ngOnInit() {
      this._securityService.isConnectedSubject.subscribe(
          (connected) => {
              this.isConnected = connected;
          });
      this._securityService.userDataSubject.subscribe(
          (userData) => {
              this.user = userData;
          });
  }

  ngOnDestroy(){
    this._securityService.isConnectedSubject.unsubscribe();
    this._securityService.userDataSubject.unsubscribe();
    this.destroy.unsubscribe();
  }

  logout(){
    this._securityService.logout()
        .subscribe((res)=> {
            if (res.status === STATUS_OK) {
                this.isConnected = false;
                this.router.navigate(['/sign-in']);
            }
        });
  }

    protected readonly getAvatar = getAvatar;
    protected readonly handleImageError = handleImageError;
}
import { Component, OnInit } from '@angular/core';
import {DEFAULT_PHOTO} from "../../../constants/constants";
import {getAvatarDoc, ImageUtils} from "../../../../@ea/helpers/image-utils";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../../shared/shared.module";
import {BookingService} from "../booking.service";
@Component({
  selector: 'app-sammary',
  templateUrl: './sammary.component.html',
  styleUrls: ['./sammary.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    CommonModule,
    SharedModule
  ],
})
export class SammaryComponent implements OnInit {
  appointement: any;
  practice: any;
  timeSlot: any;
  time: any;
  motif: any;
  dataPractice: any;
  constructor(private _bookingService: BookingService) {}

  ngOnInit(): void {
    this._bookingService.appointmentSelected.subscribe(val=>{
      if(val){
        this.appointement = val;
        this.practice = this.appointement?.practice;
        this.timeSlot = this.appointement?.timeSlot;
        this.time = this.appointement?.time;
        this.motif = this.appointement?.motif;
        this.dataPractice = {
          title:   this.practice?.title,
          speciality: this.practice?.speciality,
          city: this.practice?.city,
          picture: this.practice?.picture,
          url: this.practice?.url,
        }
      }
    })
  }

  defaultAvatarTTSUrl = DEFAULT_PHOTO
  handleImageError(event: any) {
    event.target.src = this.defaultAvatarTTSUrl;
  }
  imageUtils = new ImageUtils();
    protected readonly getAvatarDoc = getAvatarDoc;
}

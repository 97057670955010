import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import {SecurityService} from "../../main/security/security.service";
import {UserAuthenticated} from "./user-authenticated";

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(SecurityService).check().pipe(
        switchMap((authenticated: UserAuthenticated) =>
        {
            // If the user is not authenticated...
            if (authenticated?.connected) {
                return of(true);
            }
            else{
                // Redirect to the sign-in page with a redirectUrl param
                const urlTree = router.parseUrl(`sign-in`);
                return of(urlTree);
            }
        }),
    );
};

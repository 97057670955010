import { Component, Inject, PLATFORM_ID, HostListener } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import {AnalyticsService} from "./analytics.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public settings: Settings;
  constructor(public appSettings:AppSettings, 
              public router:Router, 
              @Inject(PLATFORM_ID) private platformId: Object,
              public translate: TranslateService,
              private analyticsService: AnalyticsService){
    this.analyticsService.trackPageView();
    this.settings = this.appSettings.settings;
    translate.addLangs(['en','fr']);
    translate.setDefaultLang('fr'); 
    translate.use('fr');
    // delete all previous local storage keys on first time visit
    window.onbeforeunload = (()=>{
      localStorage.removeItem("firstVisited");
      return '';
    });
    
  }

  ngAfterViewInit(){ 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {   
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0,0);
          }
        }); 
      }            
    });    
  }

}

import {Component, EventEmitter, inject, Output} from '@angular/core';
import {RecaptchaModule} from "ng-recaptcha";
import {FormGroup, FormsModule} from "@angular/forms";
import {RecaptchaService} from "./recaptcha.service";

@Component({
    selector: 'app-recaptcha',
    templateUrl: './recaptcha.component.html',
    standalone: true,
    imports: [RecaptchaModule, FormsModule],
    providers: [RecaptchaService],
})
export class RecaptchaComponent{
    @Output()
    emitRecaptcha = new EventEmitter(false);

    private recaptchaService = inject(RecaptchaService);
    resolved(captchaResponse: string) {
        // console.log(`Resolved captcha with response: ${captchaResponse}`);
        // this.recaptchaService.sendRequest(captchaResponse);
        this.emitRecaptcha.emit(true);
    }
}
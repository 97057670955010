import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import {DOCUMENT} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    private _url: string;

    /**
     * constructor
     *
     * @param {HttpClient} _httpClient
     * @param meta
     * @param document
     * @param title
     */
    constructor(private _httpClient: HttpClient,
                private meta: Meta,
                @Inject(DOCUMENT) private document: Document,
                private title: Title) {
        this._url = environment.url_api;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([

            ]).then(
                ([]) => {

                    resolve([]);
                },
                reject
            );
        });
    }
    generateTags(config: { title?: string, description?: string, keywords?: string, image?: string, url?: string } = {}): void {
        // Default SEO values for TicTacSanté Prendre Rendez-vous
        config = {
            title: 'TicTacSanté - Prendre rendez-vous avec votre médecin',
            description: 'Réservez facilement un rendez-vous avec votre médecin sur TicTacSanté. Accédez à des services de santé rapides et fiables.',
            keywords: 'Prendre rendez-vous, Médecin, Santé, TicTacSanté, Consultation médicale, Services de santé',
            image: 'https://tictacsante.com/assets/images/logos/tts-logo.avif',
            url: this.document.location.href,
            ...config
        };

        // Update meta tags
        this.meta.updateTag({ name: 'description', content: config.description, 'data-rh': 'true' });
        this.meta.updateTag({ name: 'keywords', content: config.keywords });

        // Twitter Card Tags
        this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.meta.updateTag({ name: 'twitter:site', content: '@TicTacSante' });
        this.meta.updateTag({ name: 'twitter:title', content: config.title });
        this.meta.updateTag({ name: 'twitter:description', content: config.description });
        this.meta.updateTag({ name: 'twitter:image', content: config.image });

        // Open Graph Tags
        this.meta.updateTag({ property: 'og:type', content: 'website' });
        this.meta.updateTag({ property: 'og:site_name', content: 'TicTacSanté' });
        this.meta.updateTag({ property: 'og:locale', content: 'fr_FR' });
        this.meta.updateTag({ property: 'og:title', content: config.title });
        this.meta.updateTag({ property: 'og:description', content: config.description });
        this.meta.updateTag({ property: 'og:image', content: config.image });
        this.meta.updateTag({ property: 'og:url', content: config.url });

        // Set document title
        this.title.setTitle(config.title);

        // Add canonical link
        const link: HTMLLinkElement = this.document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', config.url);
        this.document.head.appendChild(link);

        const structuredData = {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": config.title,
            "description": config.description,
            "url": config.url,
            "image": config.image,
            "publisher": {
                "@type": "Organization",
                "name": "TicTacSanté",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://tictacsante.com/assets/images/logos/tts-logo.avif"
                }
            },
            "sameAs": [
                "https://x.com/TicTacSante",
                "https://www.facebook.com/tictacsante"
            ]
        };
        const script: HTMLScriptElement = this.document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(structuredData);
        this.document.head.appendChild(script);
    }

}
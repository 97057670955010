import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    Optional,
    ViewChild
} from '@angular/core';
import {TimesSlot} from './planning.model';
import {PlanningService} from './planning.service';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators,} from '@angular/forms';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogMarkerComponent} from '../hero-map/dialog-marker/dialog-marker.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MomentDateModule} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import {SharedModule} from 'src/app/shared/shared.module';
import {CommonModule} from '@angular/common';
import {STATUS_OK} from "../../app.constants";
import {BookingInProgressService} from "../../shared/booking-in-progress/booking-in-progress.service";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {BehaviorSubject} from "rxjs";

export const MY_DATE_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'MMM DD, YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',

    },
};
export const CABINET = 'CABINET'
export const HOME = 'HOME'
export const TELECONSULTATION = 'TELECONSULTATION'

@Component({
    selector: 'app-planning',
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        MomentDateModule,
        TooltipModule,
        MatButtonModule,
    ],
    templateUrl: './planning.component.html',
    styleUrls: ['./planning.component.scss', './planning2.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
    ]
})
export class PlanningComponent implements OnInit {
    @Input() Practice: any;
    @Input() type: string;
    @Input() init_times_slots: any;
    @Input() reasons: any;
    @Input() places: any;
    @Input() nbrDays: any;

    times_slot: TimesSlot[] = [];
    motifs: any;
    display_days: number = 5;
    displayDays$ = new BehaviorSubject<number>(5);
    planningForm: FormGroup;
    isToday: boolean = true;
    isSelect: boolean = true;
    selected: string = '1';
    spin: boolean = false;
    mobwidth: any;
    isMobile: boolean = false;
    step: number = 5;
    isDropdownOpen: boolean = false;
    isDropdownPlaceOpen: boolean = false;

    nextDateApp: Date = null;
    public errorPractice       : boolean = false;
    public errorConfig         : boolean = false;
    public errorScheduler      : boolean = false;
    public errorOccurred       : boolean = false;
    public errorNewPatient     : boolean = false;
    public emptyPlanning       : boolean = false;
    public emptyPlanningTimes  : boolean = false;


    /**
     * Constructor
     *
     * @param {PlanningService} _planningService
     * @param {Router} router
     * @param {FormBuilder} _formBuilder
     * @param {MatDialogRef} dialogRef
     * @param {DateAdapter} dateAdapter
     * @param _bookingInProgressService
     */
    constructor(
        private _planningService: PlanningService,
        private _formBuilder: FormBuilder,
        public router: Router,
        @Optional() public dialogRef: MatDialogRef<DialogMarkerComponent>,
        private dateAdapter: DateAdapter<Date>,
        private _bookingInProgressService: BookingInProgressService,
        private _cdr: ChangeDetectorRef
    ) {
        this.mobwidth = window.screen.width;
        this.dateAdapter.setLocale('fr');
    }
    @HostListener('window:resize', ['$event.target'])
    onResize(target?) {
        this.handleResize(target.innerWidth);
    }
    handleResize(innerWidth): void {
        if (innerWidth <= 270) {
            this.displayDays$.next(1);
            this.isMobile = true;
        } else if (innerWidth > 270 && innerWidth <= 350) {
            this.displayDays$.next(2);
            this.isMobile = true;
        } else if (innerWidth > 350 && innerWidth <= 550) {
            this.displayDays$.next(3);
            this.isMobile = true;
        }else {
            this.displayDays$.next(5);
            this.isMobile = false;
        }
    }

    @ViewChild('btn_dropdown_place', { static: true }) elementRefPlace: ElementRef;
    @ViewChild('btn_dropdown_motif', { static: true }) elementRefMotif: ElementRef;
    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: HTMLElement): void {
        const clickedInsidePlace = this.elementRefPlace.nativeElement.contains(targetElement);
        const clickedInsideMotif = this.elementRefMotif.nativeElement.contains(targetElement);
        if (!clickedInsidePlace && !clickedInsideMotif) {
            this.isDropdownOpen      = false;
            this.isDropdownPlaceOpen = false;
        }
    }

    ngOnInit(): void {
        this.handleResize(this.mobwidth);
        this.planningForm = this._formBuilder.group(
            {
                motif: [{value:'', disabled: this.step<=3 && !this.selected}, Validators.required],
                place: ['', Validators.required],
                date_init: [moment()]
            },
            { validator: [] }
        );
        this.displayDays$.subscribe(value => {
            if(value){
                if(value != this.display_days) {
                    this.display_days = value;
                    this.load_TimesSlot();
                }
            }
        })
        if(this.nbrDays){
            this.display_days = this.nbrDays;
        }
        if(this.reasons){
            this.motifs = this.reasons;
            this.motifs.forEach(element => {
                if (element.is_default) {
                    this.planningForm.get('motif').setValue(element);
                    this.selectedType = element;
                }
            });
        }else {
            this.load_motifs(this.getPracticeRef());
        }
        if(this.places){
            if(this.places.length > 0){
                this.planningForm.get('place').setValue(this.places[0]);
                this.selectedPlace = this.places[0];
            }
        }else{
            this.load_places(this.getPracticeRef());
        }
        if(this.init_times_slots){
            this.patchValues();
        }else {
            this.load_TimesSlot();
        }
        this._cdr.detectChanges();
    }
    private getPracticeRef(){
        return this.Practice.reference_practice?
            this.Practice.reference_practice:
            this.Practice.reference;
    }
    updateCalcs(type: string, event: MatDatepickerInputEvent<Date>) {
        this.nextDateApp = null;
        this.ischosenDateToday();
        this.step5();
        this.isDropdownPlaceOpen = false;
        this.isDropdownOpen = false;
    }
    load_motifs(practiceRef){
        this._planningService.getMotifs(practiceRef).subscribe((res) => {
            if (res.status === STATUS_OK) {
                this.motifs = res.data;
                this.motifs.forEach(element => {
                    if (element.is_default) {
                        this.planningForm.get('motif').setValue(element);
                        this.selectedType = element;
                    }
                });
            }
        });
    }
    load_places(practiceRef){
        this._planningService.getPlaces(practiceRef).subscribe((res) => {
            if (res.status === STATUS_OK) {
                this.places = res.data;
                if(this.places.length > 0){
                    this.planningForm.get('place').setValue(this.places[0]);
                    this.selectedPlace = this.places[0];
                }
            }
        });
    }
    load_TimesSlot(): void {
        const params = this.planningForm.getRawValue();
        if(this.nextDateApp){
            params.date_init = (moment(this.nextDateApp)).format('yyyy-MM-DD');
            this.planningForm.get("date_init").patchValue(moment(this.nextDateApp));
        }else {
            params.date_init = (moment(this.planningForm.getRawValue().date_init)).format('yyyy-MM-DD');
        }
        let refType = '';
        if(params.motif !== '') refType = params.motif.reference;
        const params_timesSlote = {
            date_init: params.date_init,
            display_days: this.display_days,
            reference: this.getPracticeRef(),
            refType: refType
        };

        this.spin = true;
        this._planningService.getTimesSlot(params_timesSlote).subscribe((res) => {
            this.spin = false;
            if (res.status === STATUS_OK) {
                this.init_times_slots = res?.data;
                this.patchValues();
            }else {
                //Todo do action
            }
        });
    }
    startProcessRdv(time: any, timeSlot: any): void {
        const dataForm = this.planningForm.getRawValue();
        const dataParams = {
            idPraticien: this.getPracticeRef(),
            reference: this.getPracticeRef(),
            hour: time.full_hour,
            is_default: '',
            type_ref: dataForm.motif.reference,
            date_init: timeSlot.date,
            consultation_type: this.selectedTypeConsultation,
            consultation_place: this.selectedPlace.reference
        };
        this._planningService.startProcessRdv(dataParams).subscribe((res) => {
            const rendezVous = {
                praticien: dataParams.idPraticien,
                Hour: dataParams.hour,
                isconnect: false,//todo always false
                date: dataParams.date_init,
                motif: dataParams.type_ref,
                consultation_type: this.selectedTypeConsultation,
                place: this.selectedPlace,
            };
            this._planningService.selectedTime({
                rendez_vous: rendezVous,
            });
            const appointement = {
                practice: this.Practice,
                time: time,
                timeSlot: timeSlot,
                motif: dataForm.motif,
                consultation_type: this.selectedTypeConsultation,
                place: this.selectedPlace,
                created_at: new Date().toISOString(),
            };
            localStorage.setItem('appointement', JSON.stringify(appointement));
            this._bookingInProgressService.isAlreadyClosedBookingInProgress.next(false);
            if (this.type === 'marker') {
                this.closeDialog();
            }
            this.router.navigate(['/booking']);
        });
    }
    closeDialog() {
        this.dialogRef.close(true);
    }
    ischosenDateToday(): any {
        if(this.planningForm.controls['date_init'].value.isBefore(moment(), 'day')){
            this.planningForm.controls['date_init'].patchValue(moment());
        }else{
            this.isToday = this.planningForm.controls['date_init'].value.isSame(moment(), 'day');
        }
    }
    nextDays(): void {
        this.nextDateApp = null;
        const newDate = this.planningForm.controls['date_init'].value.add(this.display_days, 'days');
        this.planningForm.controls['date_init'].setValue(newDate);
        this.spin = false;
        this.load_TimesSlot();
        this.ischosenDateToday();
    }
    resetVarErrors(){
        this.nextDateApp = null;
        this.emptyPlanning = false;
        this.errorPractice = false;
        this.errorScheduler = false;
        this.errorConfig = false;
        this.errorOccurred = false;
        this.emptyPlanningTimes = false;
    }
    patchValues(){
        this.resetVarErrors();
        if(this.init_times_slots?.planning){
            this.times_slot = this.init_times_slots?.planning;

        }
        if(this.init_times_slots?.allows_new_patients === true){
            this.errorNewPatient = true;
            this.times_slot = [];

        }else if(this.init_times_slots?.error_practice ||
            this.init_times_slots?.error_config ||
            this.init_times_slots?.error_scheduler){
            this.errorPractice = true;
            this.errorScheduler = true;
            this.errorConfig = true;
            this.errorOccurred = true;
            this.times_slot = [];

        }else if(this.init_times_slots?.empty_planning){
            this.emptyPlanning = true;

        }else if(this.init_times_slots?.empty_planning_times){
            this.emptyPlanningTimes = true;

            if(this.init_times_slots?.next_available_date){
                this.nextDateApp = this.init_times_slots?.next_available_date;

            }else{
                //todo: what to do in this case
                this.errorOccurred = true;

            }
        }
    }

    previousDays(): void {
        this.nextDateApp = null;
        const newDate = this.planningForm.controls['date_init'].value.subtract(this.display_days, 'days');
        this.planningForm.controls['date_init'].setValue(newDate);
        this.load_TimesSlot();
        this.ischosenDateToday();
    }

    selectedType: any = null;
    step5(motif?): void {
        if(motif){
            this.selectedType = motif;
            this.planningForm.controls['motif'].patchValue(motif);
            this.isDropdownOpen = !this.isDropdownOpen;
        }
        this.nextDateApp = null;
        if (!this.errorOccurred) {
            this.step = 5;
            this.load_TimesSlot();
        }
    }
    goToNextDateApp() {
        this.load_TimesSlot();
    }
    selectedTypeConsultation: string = CABINET;
    selectType(type: string) {
        this.selectedTypeConsultation = type;
    }

    protected readonly CABINET = CABINET;
    protected readonly HOME = HOME;
    protected readonly TELECONSULTATION = TELECONSULTATION;

    checkDate(date: any) {
        return moment(date, "YYYY-MM-DD").isSame(moment(), 'day');
    }

    public getFullAddress(address: string, city: string, country: string, zip_code: string): string {
        let val = "";
        if (address) {
            val = address;
        }
        if (city) {
            if (val) {
                val += ", " + city;
            } else {
                val = city;
            }
        }
        if (country) {
            if (val) {
                val += ", " + country;
            } else {
                val = country;
            }
        }
        if (zip_code) {
            if (val) {
                val += ", " + zip_code;
            } else {
                val = zip_code;
            }
        }
        return val;
    }

    selectedPlace: any = null;
    selectPlace(place: any) {
        if(place){
            this.selectedPlace = place;
            this.planningForm.controls['place'].patchValue(place);
        }
        this.isDropdownPlaceOpen = !this.isDropdownPlaceOpen;
    }

    getTypeConsultationLabel(selectedTypeConsultation: string) {
        switch (selectedTypeConsultation) {
            case CABINET:
                return 'Consultation au cabinet';
            case HOME:
                return 'Consultation à domicile';
            case TELECONSULTATION:
                return 'Téléconsultation';
            default:
                return '';
        }
        return "";
    }
}
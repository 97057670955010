import { Component } from '@angular/core';
import {SignInComponent} from "./google/sign-in.component";
import {
  FacebookLoginProvider,
  GoogleLoginProvider, SocialAuthService,
  SocialAuthServiceConfig,
  SocialLoginModule
} from "@abacritt/angularx-social-login";
import {SecurityService} from "../security.service";
import {STATUS_OK} from "../../../app.constants";
import {Router} from "@angular/router";
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in-page.component.html',
  standalone: true,
  imports:[SignInComponent, SocialLoginModule],
  providers : [
    SocialAuthService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider
            ('239415642847-ui5ojj2mqe1s4n310vvgompi3v1sv73l.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('426812791556548')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ]
})

export class SignInPageComponent {
  constructor() {}
}
import {
    DEFAULT_PHOTO,
    defaultPhoto,
    PATH_AVATAR,
    PATH_COVER,
    PATH_DOC,
    PATH_GALLERY
} from "../../app/constants/constants";
//Check if is valid url image
const isImageUrl = (url: string): boolean => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*\.(jpeg|jpg|gif|png|webp)$/i;
    return urlRegex.test(url);
};
//Image Utils class
export class ImageUtils{
    getPhoto(photoUrl: string) {
        const isValidUrl = isImageUrl(photoUrl);
        if(isValidUrl){
            return photoUrl;
        }else return defaultPhoto;
    }
}
//Patients
export function getAvatar(avatar) {
    if (isImageUrl(avatar)) {
        return avatar;
    }
    if (avatar === 'Default.png' || !avatar) {
        return DEFAULT_PHOTO;
    }
    return avatar ? PATH_AVATAR + avatar : DEFAULT_PHOTO;
}
//Practices
export function getAvatarDoc(avatar) {
    if (isImageUrl(avatar)) {
        return avatar;
    }
    if (avatar === 'Default.png' || !avatar) {
        return DEFAULT_PHOTO;
    }
    return avatar ? PATH_DOC + avatar : DEFAULT_PHOTO;
}
//Handle Image Error
export function handleImageError(event: any) {
    event.target.src = DEFAULT_PHOTO;
}
//Get Image gallerie
export function getImageGallery(image) {
    return PATH_GALLERY + image?.picture;
}
//Get cover
export function getCover(cover) {
    if (isImageUrl(cover)) {
        return cover;
    }
    if (!cover || cover == 'cover.png'){
        return 'assets/images/cover/cover.webp'
    }
    return PATH_COVER + cover;
}
export function emptyPlanningData(daysCount: number) {
    const daysOfWeekShort = ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'];
    const daysOfWeekFull = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const monthsShort = ['Janv.', 'Févr.', 'Mars', 'Avr.', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'];
    const monthsFull = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

    const today = new Date();
    const year = today.getFullYear();
    const monthNumber = today.getMonth();
    const lastDayOfMonth = new Date(year, monthNumber + 1, 0).getDate();
    const startDay = today.getDate();
    const endDay = Math.min(startDay + daysCount - 1, lastDayOfMonth);

    const daysData = [];

    for (let day = startDay; day <= endDay; day++) {
        const currentDate = new Date(year, monthNumber, day);
        const dayOfWeek = currentDate.getDay();

        daysData.push({
            date: currentDate.toISOString().split('T')[0],
            day: day,
            times: [],
            year: year,
            month: monthsFull[monthNumber],
            day_name: daysOfWeekShort[dayOfWeek],
            full_day_name: daysOfWeekFull[dayOfWeek],
            count_slots: 0,
            month_name: monthsShort[monthNumber],
            day_number: day - startDay + 1,
            month_number: monthNumber + 1,
            last_day: lastDayOfMonth,
            is_close: false,
            start_hour: 8,
            end_hour: 18,
            emergency: false,
            day_locked: false
        });
    }

    return {"planning": daysData};
}
import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
// import {RECAPTCHA_BODY} from "./request";

// declare global {
//     interface Window {
//         grecaptcha: any;
//     }
// }
@Injectable({
    providedIn: 'root'
})
export class RecaptchaService {

    constructor(private httpClient: HttpClient) { }

    /*execute(action: string): Promise<string> {
        return new Promise((resolve, reject) => {
            window.grecaptcha.enterprise.ready(() => {
                window.grecaptcha.enterprise.execute('6LcX0rIqAAAAAHmtTcLAochMycNnpSAIIoDhhkOl', { action })
                    .then((token: string) => {
                        resolve(token);
                        this.sendRequest(token);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        });
    }*/

    sendRequest(token){
        this.httpClient.post(
            "https://recaptchaenterprise.googleapis.com/v1/projects/tactical-orbit-435508-a0/assessments?key=AIzaSyATAd7Rjyy85oIBbXFlL7iwsxpw4tpJKMc",
            {
                "event": {
                    "token": token,
                    "expectedAction": "USER_ACTION",
                    "siteKey": "6Ldy37IqAAAAAGOrWOC_cdQwz9auGtZl0NlrwPci",
                }
            }
        ).subscribe((res: Response) => {
            console.log(res);
        })
    }
}
import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import {SecurityService} from "../../main/security/security.service";
import { of, switchMap } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(SecurityService).check().pipe(
        switchMap((authenticated) =>
        {
            // If the user is not authenticated...
            if (authenticated?.connected) {
                return of(router.parseUrl(''));
            }
            else{
                return of(true);
            }
        }),
    );
};

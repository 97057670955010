import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {BehaviorSubject, Observable, of} from 'rxjs';
import { UtilsService } from 'src/app/helpers/utils.service';
import {EaHttpAbstract} from "../../../@ea/abstract/http.abstract";
import {map} from "rxjs/operators";
import {STATUS_OK} from "../../app.constants";
import {UserAuthenticated} from "../../helpers/guards/user-authenticated";

@Injectable({
    providedIn: 'root'
})
export class SecurityService {

    private _url: string;
    isConnectedSubject = new BehaviorSubject<boolean>(null);
    userDataSubject = new BehaviorSubject<any>(null);
    nextStepSubject = new BehaviorSubject<boolean>(null);

    emitIsConnected(isConnected) {
        this.isConnectedSubject.next(isConnected);
    }
    emitDataUser(userData){
        this.userDataSubject.next(userData);
    }
    constructor(
        private _utilsService : UtilsService,
        private _eaHttpAbstract: EaHttpAbstract)
    {
        this._url = environment.url_api;
        this.initConnection().subscribe();
    }

    /*
    * Init Connections
    * */
    public initConnection(){
        return this.authenticated().pipe(
            map((res: UserAuthenticated) => {
                localStorage.setItem("authenticated", JSON.stringify(res));
                this.isConnectedSubject.next(res?.connected);
                if(res?.connected) {
                    const userdata = this._utilsService.getSession("userdata");
                    if(!userdata){//Todo: Maybe we can remove this condition and fetch the data of the user connected directly
                        this.getDataUser().subscribe(res => {
                            if(res.status === STATUS_OK){
                                this.userDataSubject.next(res?.data?.data_user);
                                this._utilsService.setSession("userdata", res?.data?.data_user);
                            }
                        })
                    }else{
                        if(typeof userdata === "string"){
                            this.userDataSubject.next(JSON.parse(userdata));
                        }else{
                            this.userDataSubject.next(userdata);
                        }
                    }
                }else {
                    this.userDataSubject.next(null);
                    this.isConnectedSubject.next(false);
                    this._utilsService.removeSession("userdata");
                }
                return res;
            })
        );
    }

    /**
   * sign-in
   *
   * @returns Promise<any>
   **/
    authentication(login, password): Observable<any> {
        const data = {
            login,
            password,
            type:'front'
        }
        return this._eaHttpAbstract.post("/security/auth/sign_in", data).pipe(
            map((response: any)=>{
                if(response.status === STATUS_OK) {
                    const dataUser = response?.data?.data_user;
                    this.emitDataUser(dataUser);
                    this._utilsService.setSession("userdata", dataUser)
                    this.initConnection().subscribe();
                }else{
                    this.emitIsConnected(false);
                }
                return response;
            })
        );
    }
    /**
    * user connected
    **/
    /**
     * Authenticated
     */
    authenticated(): Observable<UserAuthenticated> {
        return this._eaHttpAbstract.post('/security/auth/authenticated', {})
            .pipe(
                map((response: any) => {
                    const res: UserAuthenticated = response?.data;
                    return res;
                })
        );
    }

    /**
     * logout
     *
     * @returns Promise<any>
    **/
    logout(): Observable<any> {
        return this._eaHttpAbstract.post("/security/auth/logout", {})
            .pipe(
                map((response: any)=>{
                    if (response.status === STATUS_OK) {
                        this._utilsService.removeSession("userdata");
                        this._utilsService.removeSession('authenticated');
                        this.emitIsConnected(false);
                        this.userDataSubject.next(null);
                    }
                    return response;
                })
            );
    }

    /**
    * Get data user
    *
    * @returns Promise<any>
    **/
    getDataUser(): Observable<any> {
        return this._eaHttpAbstract.post("/security/auth/user_data", {})
            .pipe(
                map((response: any)=>{
                    return response;
                })
            );
    }

    /**
     * Forgot password
     *
     * @param contact
     * @param type
     */
    forgotPassword(contact: any, type): Observable<any> {
        return this._eaHttpAbstract
            .post("/security/forgot_password", { contact, type })
            .pipe(
                map((response: any) => {
                    //   if (response.body.status === STATUS_OK) {
                    return response;
                    //   }
                })
            );
    }

    /**
     * Code validation
     *
     */
    codeValidation(verification_code: string): Observable<any> {
        return this._eaHttpAbstract
            .post(`/security/verification_code`, { verification_code })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**
     * Reset password
     *
     * @param new_password
     */
    resetPassword(new_password: string): Observable<any> {
        return this._eaHttpAbstract
            .post("/security/reset_password", { new_password })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    initInscription(data: any): Observable<any> {
        const _data = {
            civility: data.genre,
            first_name: data.firstname,
            last_name: data.lastname,
            mobile_phone: data.mobile_phone,
            gsm: data.mobile,
            country: "Maroc",
            iso2: "ma",//todo
            prefix: data.prefix,//todo
            email: data.email,
            password: data.password,
        };
        return this._eaHttpAbstract.post("/security/register_new_patient", _data).pipe(
            map(response=>{
                return response;
            })
        );
    }

    /**
     * Retreive Person Data
     * * @param validationcode
     * *@param data: Array
     * * @returns {Promise<any>}
     */
    validateInscription(data: any): Observable<any> {
        return this._eaHttpAbstract.post("/security/validate_inscription", {validation_code: data.code})
            .pipe(
                map(response=>{
                    return response;
                })
            );
    }

    /**
    * Retreive Person Data
    * 
    * * @returns {Promise<any>}
    */
    socialAuthentication(data: any): Observable<any> {
        let _data: any = {}
        if(data.provider === 'GOOGLE') {
            _data.google_id = data.id;
            _data.type = 'google';
            _data.first_name = data.firstName;
            _data.email = data.email;
            _data.last_name = data.lastName;
            _data.picture = data.photoUrl;
            _data.id_token = data.idToken;
        } else if(data.provider === 'FACEBOOK') {
            _data.facebook_id = data.id;
            _data.type = 'facebook';
            _data.first_name = data.firstName;
            _data.email = data.email;
            _data.last_name = data.lastName;
            _data.picture = data.photoUrl;
            _data.id_token = data.authToken;
        }
        return this._eaHttpAbstract.post('/security/auth/social', _data).pipe(
            map((response: any)=>{
                if(response.status === STATUS_OK) {
                    const dataUser = response?.data?.data_user;
                    this.emitDataUser(dataUser);
                    this._utilsService.setSession("userdata", dataUser)
                    this.initConnection().subscribe();
                }else{
                    this.emitIsConnected(false);
                }
                return response;
            })
        );
    }

    /*
    * Check is if user authenticated: used for auth Guard
    * */
    check(): Observable<UserAuthenticated> {
        //Todo we can use this.initConnection();
        const authenticatedStorage = localStorage.getItem("authenticated");
        if(!authenticatedStorage) {
            return this.authenticated().pipe(
                map((res: UserAuthenticated) => {
                    localStorage.setItem("authenticated", JSON.stringify(res));
                    return res;
                })
            );
        }else {
            //Todo check from api
            return of(JSON.parse(authenticatedStorage));
        }
    }
}

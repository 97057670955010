import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {map} from "rxjs/operators";
import {STATUS_OK} from "../../app.constants";
import {EaHttpAbstract} from "../../../@ea/abstract/http.abstract";

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  procheSelected;
  procheSelectedSubject = new BehaviorSubject<any>({proche : '',comment : ''});
  appointmentSelected = new BehaviorSubject<any>(null);
  constructor(private _eaHttpAbstract: EaHttpAbstract) {}
  rdvSubject = new BehaviorSubject<any>(null);
  rdv : any;
  emitRdv(){
        this.rdvSubject.next(this.rdv);
    }

  confirmerRdv(data): Observable<any>{
        const _data = {
            comment: data.comment,
            patient_reference: data.idProche,
        };
        return this._eaHttpAbstract.post("/public/booking/confirm_appointment", _data)
            .pipe(
                map((response: any)=>{
                    if(response.status === STATUS_OK){
                        this.rdv = response.data;
                        this.emitRdv();
                        localStorage.removeItem('appointement');
                    }
                    return response;
                })
            );
    }

  selectProche(proche){
    this.procheSelected = proche;
    this.emitProche();
  }

  emitProche(){
    this.procheSelectedSubject.next(this.procheSelected);
  }

  checkAvailabilitySlot(data): Observable<any>{
      return this._eaHttpAbstract.post("/public/booking/check_slot_availability", data);
  }
}
